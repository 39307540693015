export default {
  "": {
    "id": 1066,
    "temp_key": "",
    "site_id": 28799,
    "name": "",
    "items": [
      {
        "blank": null,
        "document": null,
        "id": "21udxehorzd4",
        "name": "Úvod",
        "page": "/",
        "section": "uvod",
        "url": null,
        "use": "page",
        "elements": []
      },
      {
        "blank": null,
        "document": null,
        "id": "sbj6uwny03hj",
        "name": "Místo",
        "page": "/",
        "section": "misto",
        "url": null,
        "use": "page",
        "elements": []
      },
      {
        "blank": null,
        "document": null,
        "id": "l1xs4kkz66nl",
        "name": "Harmonogram",
        "page": "/",
        "section": "harmonogram",
        "url": null,
        "use": "page",
        "elements": []
      },
      {
        "blank": null,
        "document": null,
        "id": "3tw79xxoa2r1",
        "name": "Nocleh",
        "page": "/",
        "section": "nocleh",
        "url": null,
        "use": "page",
        "elements": []
      },
      {
        "blank": null,
        "document": null,
        "id": "pntndowdhtox",
        "name": "Svatební dar",
        "page": "/",
        "section": "svatebnidar",
        "url": null,
        "use": "page",
        "elements": []
      },
      {
        "blank": null,
        "document": null,
        "id": "d7xpus2bh2u1",
        "name": "Potvrdit účast",
        "page": "/",
        "section": "166zeilvmwg",
        "url": null,
        "use": "page",
        "elements": []
      }
    ]
  }
}