/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, SeparateLine, SeparateLineWrap, Text, Button, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hlinkovi"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--80" style={{"backgroundColor":"#607f5a"}} name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" style={{"backgroundColor":"rgba(96,127,90,1)"}} anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":670}} src={"https://cdn.swbpg.com/t/36570/1a2c5a272c954df0be6106189882805a_s=860x_.jpg"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/36570/1a2c5a272c954df0be6106189882805a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36570/1a2c5a272c954df0be6106189882805a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36570/1a2c5a272c954df0be6106189882805a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36570/1a2c5a272c954df0be6106189882805a_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--154 lh--1" content={"<span style=\"color: var(--color-custom-2);\">Adéla &amp; Vašek</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" style={{"backgroundColor":"#607f5a"}} name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36" content={"Srdečně Vás zveme na naši svatbu! Oslavte s námi zrod manželů Hlinkových!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"cara"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"infobude"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"Veškeré informace budeme průběžně doplňovat, proto doporučujeme se na web sem tam vrátit."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"cara"}>
          
          <SeparateLineWrap style={{"backgroundColor":"rgba(96,127,90,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"misto"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Obřad"}>
              </Title>

              <Text className="text-box" content={"Evangelický kostel<br>256 01 Soběhrdy-Benešov u Prahy<br>"}>
              </Text>

              <Text className="text-box" content={"<br>Svatební obřad bude trvat přibližně jednu hodinu. Po obřadu s námi prosím ještě chvíli setrvejte na společné focení, které proběhne buď před, nebo v kostele, v závislosti na počasí. Po focení na Vás bude čekat malé pohoštění v podobě svatebních koláčků a nealko osvěžení.<br><br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn2 btn-box--pbtn2 btn-box--shape5 btn-box--cColor2 btn-box--filling2" url={"https://www.google.com/maps/place/Evangelick%C3%BD+kostel/@49.8219472,14.7370581,17z/data=!4m6!3m5!1s0x470c792c9636733b:0x4c7d63eb153e75df!8m2!3d49.8218572!4d14.7380452!16s%2Fg%2F11dymkfsql?hl=cs&entry=ttu"} href={"https://www.google.com/maps/place/Evangelick%C3%BD+kostel/@49.8219472,14.7370581,17z/data=!4m6!3m5!1s0x470c792c9636733b:0x4c7d63eb153e75df!8m2!3d49.8218572!4d14.7380452!16s%2Fg%2F11dymkfsql?hl=cs&entry=ttu"} content={"Odkaz na mapu"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Hostina"}>
              </Title>

              <Text className="text-box" content={"Lovecká chata<br>(u Konopiště, kousek od restaurace Stará myslivna)<br>"}>
              </Text>

              <Text className="text-box" content={"<br>Hostina bude probíhat převážně venku na zahradě pod stany. Vyměňte proto odpoledne šaty a střevíčky za chatařský oděv a oblečte se především tak, abyste měli pohodlí a byli pokud možno v teple a v suchu. S naším vysněným sluníčkovým počasím již počítat nemůžeme. Doufáme však, že Vám pár kapek nezabrání v tom&nbsp;užít si s námi naši vysněnou svatbu.<br><br>Info pod čarou: na okrajích zahrady je povoleno čůrat.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn2 btn-box--pbtn2 btn-box--shape5 btn-box--cColor2 btn-box--filling2" url={"https://www.google.com/maps/place/49%C2%B046'39.9%22N+14%C2%B039'05.0%22E/@49.7777667,14.6512319,18.4z/data=!4m4!3m3!8m2!3d49.7777603!4d14.6513849?entry=ttu"} href={"https://www.google.com/maps/place/49%C2%B046'39.9%22N+14%C2%B039'05.0%22E/@49.7777667,14.6512319,18.4z/data=!4m4!3m3!8m2!3d49.7777603!4d14.6513849?entry=ttu"} content={"Odkaz na mapu"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"19j8vg3y7sq"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingBottom":0,"marginTop":0,"paddingTop":0}} name={"m3pjr8gbggm"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"rgba(96,127,90,1)"}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Jak se na místo dostat a kde zaparkovat?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"9ngkikrsbm"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Obřad"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":599}} content={"Evangelický kostel se nachází na okraji obce Soběhrdy. Svá vozidla prosím zaparkujte za kostelem na místě označeném na mapě. Nelekejte se, že se nejedná o oficiální parkoviště, ale pouze o štěrkovou plochu, kterou nám obec k tomuto účelu zapůjčí."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36570/07f0e34ca35a4d1fa4ce6323596a2ef1_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36570/07f0e34ca35a4d1fa4ce6323596a2ef1_s=350x_.png 350w, https://cdn.swbpg.com/t/36570/07f0e34ca35a4d1fa4ce6323596a2ef1_s=660x_.png 660w, https://cdn.swbpg.com/t/36570/07f0e34ca35a4d1fa4ce6323596a2ef1_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36570/851ffbe5dfa449e4926028d52e1242fd_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/36570/851ffbe5dfa449e4926028d52e1242fd_s=350x_.png 350w, https://cdn.swbpg.com/t/36570/851ffbe5dfa449e4926028d52e1242fd_s=660x_.png 660w, https://cdn.swbpg.com/t/36570/851ffbe5dfa449e4926028d52e1242fd_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Hostina&nbsp;"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":592}} content={"Cesta na Loveckou chatu (na mapě označena žlutě) se odvíjí především od Vámi zvoleného způsobu dopravy a místa přespání.&nbsp;<br><span style=\"font-weight: bold;\"><span style=\"text-decoration-line: underline;\">Přijedete do Benešova vlakem?</span> </span>Využijte pěší stezku, která vede až na místo určení. Z nádraží následujte ukazatele směr Konopiště a na Loveckou chatu následně dojděte po červeně označené trase.<br><span style=\"font-weight: bold; text-decoration-line: underline;\">Přespáváte v některém z ubytovacích zařízení u Konopiště?</span> Zaparkujte své vozidlo na jeho parkovišti a dojděte k Lovecké chatě po červeně označené trase.<br><span style=\"font-weight: bold; text-decoration-line: underline;\">Nebudete spát na Lovecké chatě ani nikde v přilehlém okolí?</span> Zaparkujte své vozidlo na jednom ze dvou vyznačených parkovišť na mapě a dojděte k Lovecké chatě po červeně označené trase.<br><span style=\"font-weight: bold; text-decoration-line: underline;\">Budete spát ve stanu u Lovecké chaty?</span> Pokud pojedete autem, dojeďte až k Lovecké chatě po modře označené cestě. Před vjezdem na pozemek chaty se na rozcestí nachází parkovací místo pro až 7 aut, které zde budou moci zůstat přes noc. Z tohoto důvodu Vás prosíme, abyste svá vozidla využili na maximum. Současně prosíme řidiče, aby nám dali vědět, koho v autě povezou, případně kolik volných míst jim zbývá, abychom mohli zajistit odvoz všem táborníkům. Každé vozidlo, které bude chtít přijet až k chatě, od nás současně bude muset obdržet povolení pro vjezd do zámeckého parku.<br><br>Moc Vám děkujeme za spolupráci a s jakýmikoliv dotazy se na nás neváhejte obrátit!<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"19j8vg3y7sq"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"harmonogram"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Harmonogram"}>
              </Title>

              <Text className="text-box" content={"(časy odpoledního programu jsou spíše orientační)"}>
              </Text>

              <Subtitle className="subtitle-box" content={"10:30 - příjezd svatebčanů"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"11:00 - obřad&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"12:00 - společné focení"}>
              </Subtitle>

              <Text className="text-box" content={"... a malé pohoštění v podobě svatebních koláčků a nealko nápojů. Vzhlede k počasí proběhne focení pravděpodobně uvnitř kostela. Po obřadu nám proto prosím nikam neutíkejte."}>
              </Text>

              <Subtitle className="subtitle-box" content={"13:30 - rodinný oběd"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Oběd proběhne na Lovecké chatě jen v nejužším rodinném kruhu (s rodiči, prarodiči a sourozenci). Ostatní hosty prosíme, aby využili tento čas k osušení slz, malému obědu, ubytování se a přezutí/převlečení na nadcházející zábavu."}>
              </Text>

              <Subtitle className="subtitle-box" content={"14:45 - otevření bran Lovecké chaty"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Táborníci se mohou vydat k Lovecké chatě a postavit své stany, aby mohli po probdělé noci pohodlně upadnout rovnou do spacáku. Stejně tak i ostatní svatebčané se mohou pomalu scházet na první pivo."}>
              </Text>

              <Subtitle className="subtitle-box" content={"15:30 - slavnostní přípitek a první tanec novomanželů"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"16:00 - krájení dortu<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"16:30 - raut a další svatební zábava..."}>
              </Subtitle>

              <Text className="text-box" content={"Těšit se můžete na pečené prase, točené alko i nealko i živou hudbu."}>
              </Text>

              <Subtitle className="subtitle-box" content={"21:00 - afterparty DJ Čobola"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"23yo1qxr9vk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left pt--60" style={{"paddingBottom":0,"backgroundColor":"#607f5a"}} name={"nocleh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Nocleh"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Hostům s tábornickou duší nabízíme možnost přespání ve vlastním stanu přímo na zahradě Lovecké chaty."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Máte-li zájem o přespání v blízkosti Lovecké chaty, avšak stan pro Vás není to pravé, doporučujeme využít <a href=\"https://www.penzion-konopiste.cz/\" target=\"_blank\">Penzionu Konopiště</a> či <a href=\"https://www.hotel-konopiste.cz/\" target=\"_blank\">Hotelu Konopiště Nová myslivna</a>, tak jako nevěsta a ženich s rodinami."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"49cu3bluic1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"marginTop":0,"paddingTop":0,"paddingBottom":0,"backgroundColor":"#607f5a"}} name={"3v1j2axi1bn"}>
          
          <ColumnWrap className="column__flex el--1" style={{"paddingBottom":40}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" style={{"maxWidth":792,"paddingLeft":6,"paddingRight":0}} content={"Pokud nebudete chtít v místě hostiny přespávat, můžete krom vlastního automobilu či taxislužeb využít i přímého autobusového spojení linek 438 a 755 mezi Konopištěm a vlakovým nádražím v Benešově."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"yp1a3a3r9hd"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pt--60" style={{"backgroundColor":"#607f5a","paddingBottom":37}} layout={"l13"} name={"svatebnidar"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Svatební dar"}>
              </Title>

              <Text className="text-box" content={"Nějaký ten rok už spolu žijeme na hromádce, a domácnost tak máme vybavenou. Budeme Vám však velmi vděční za jakýkoliv finanční příspěvek na naši veselku. Krabici na svatební dary naleznete v kostele i následně v Lovecké chatě."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"paddingLeft":33,"maxWidth":515,"paddingRight":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36570/c7b05e1d296b48b2b48817f4408cbdb7_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/36570/c7b05e1d296b48b2b48817f4408cbdb7_s=350x_.png 350w, https://cdn.swbpg.com/t/36570/c7b05e1d296b48b2b48817f4408cbdb7_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l13"} style={{"backgroundColor":"#607f5a","paddingTop":0,"paddingBottom":0}} name={"hfmlk1hexaq"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Text className="text-box" style={{"paddingRight":0,"maxWidth":601,"paddingLeft":5}} content={"Pokud s sebou nechcete nosit hotovost nebo se zúčastníte pouze svatebního obřadu, ale chtěli byste nám přispět do naší novomanželské kasičky, můžete využít zde uvedený QR kód.&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36570/65e032403fe64456b3f7547da92f83ee_s=350x_.jfif"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} style={{"maxWidth":231,"paddingLeft":32}} srcSet={"https://cdn.swbpg.com/t/36570/65e032403fe64456b3f7547da92f83ee_s=350x_.jfif 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"166zeilvmwg"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"1bb6fvgcqf8"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Fotografie"}>
              </Title>

              <Text className="text-box" content={"Budeme moc rádi, pokud s námi budete sdílet své fotografie a videa ze svatebního dne. Ve společné složce později najdete i profesionální fotodokumentaci naší fotografky."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3 btn-box--shape5 btn-box--cColor2 btn-box--filling2" style={{"backgroundColor":"rgba(96,127,90,1)"}} content={"<span style=\"color: var(--color-custom-2);\">Odkaz na složku</span>"} url={"https://drive.google.com/drive/folders/14Nn_6IPIhqmQA7qZ9rl4a8M4kaAta_PS?usp=drive_link"} href={"https://drive.google.com/drive/folders/14Nn_6IPIhqmQA7qZ9rl4a8M4kaAta_PS?usp=drive_link"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"166zeilvmwg"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"castokladenedotazy"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Často kladené dotazy"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Je stanovený dress code?"}>
              </Subtitle>

              <Text className="text-box" content={"Žádný dress code laděný do určitého tématu či barevné kombinace stanovený není. Žádáme Vás pouze o oděv vhodný na svatební obřad v kostele. Vše ostatní ponecháváme na Vašich preferencích. Současně upozorňujeme, že místo konání svatební hostiny je velmi chatařské. Doporučujeme proto mít s sebou na přezutí pohodlné boty bez podpatku, ve kterých můžete protančit večer. Hostina se zároveň odehrává převážně venku, volte proto oblečení dle očekávaného počasí.&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Máme si zařizovat ubytování?"}>
              </Subtitle>

              <Text className="text-box" content={"Ubytování v Hotelu Konopiště Nová myslivna je zajištěné pouze pro naše rodiče, prarodiče a sourozence. Ostatním hostům nabízíme možnost přespání ve stanech přímo v místě konání hostiny. Pokud máte zájem o přespání v pohodlí vlastního pokoje, prosíme Vás o vlastní zařízení rezervace. Odkazy na nejbližší ubytovací zařízení naleznete v sekci \"Nocleh\"."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Jak je to s malými dětmi a psy?"}>
              </Subtitle>

              <Text className="text-box" content={"Všechny milované členy Vaší rodiny v náš svatební den rádi uvidíme. Sami však prosím zvažte, zda a jakých částí dne se zvládnout zúčastnit. Svatební obřad bude trvat cca 1 hodinu. Respektujte prosím naše přání strávit tuto jedinečnou chvíli v tichosti a bez vyrušení.&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Únos nevěsty je přísně zakázán!"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#607f5a"}} name={"166zeilvmwg"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#607f5a"}} name={"formular"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Přijdeš?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3 --style3" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":""},{"name":"Přivedu +1 ","type":"checkbox"},{"name":"Jméno tvého doprovodu","type":"text","placeholder":""},{"name":"Přijdou i děti? (napiš jména)","type":"text","placeholder":" "},{"name":"Zúčastním se...","type":"select","options":[{"id":"ghzxbs2s7kbw","value":"...obřadu i hostiny"},{"id":"f6lw73w6rj0b","value":"...pouze obřadu"},{"id":"wgm7ojjrmod9","value":"...pouze hostiny"},{"id":"4vedzfwgznrp","value":"nezúčastním se"}],"placeholder":" "},{"name":"Preferovaná dieta","type":"select","options":[{"id":"sj18nf3ih2ek","value":"Bez omezení"},{"id":"qv0dgay6qb24","value":"Vegetarián"},{"id":"qc6k5hylzen4","value":"Vegan"},{"id":"cuhc9wggq4g","value":"Bezlepek"}],"required":true,"placeholder":" "},{"name":"Preferovaná dieta tvého doprovodu","type":"select","options":[{"id":"1f3xf2us6ooc","value":"Bez omezení"},{"id":"upl72dwd02pn","value":"Vegetarián"},{"id":"25056ctsrihu","value":"Vegan"},{"id":"lvp8p7545d8d","value":"Bezlepek"}],"placeholder":" "},{"name":"Mám(e) zájem o přespání ve stanu","type":"checkbox"},{"name":"Chceš nám ještě něco vzkázat?","type":"textarea","required":false,"placeholder":""},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" style={{"backgroundColor":"#607f5a"}} name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Těšíme se na Vás!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}