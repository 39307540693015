import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing6 --size-XL --transparent" type={"SiteHeader"} fixed={true} layout={"3"} transparent={true} backgroundColor={"rgba(96,127,90,1)"} social={null} title={{"color":"rgba(255,255,255,1)","content":"14. 9. 2024","className":"fs--48 w--300"}} button={{"content":"","className":"btn-box"}} item={{"color":"rgba(255,255,255,1)","colorHover":"rgba(255,255,255,0.67)","className":"ff--2 fs--20 w--300"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}